<template>
  <v-slide-group
    v-if="shouldUseCarousel"
    @click:next="incrementCenteredItem"
    @click:prev="decrementCenteredItem"
    center-active
    v-model="centeredItem"
    show-arrows="always"
  >
    <div :style="carouselSubgridContainerStyles" class="subgrid-container-carousel">
      <v-slide-item v-for="(p, i) in filteredPackages">
        <div :class="packageItemClass(i)">
          <PackageNew
            v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
            :class="isFlipped[i] ? 'flipped-package' : ''"
            :packageData="p"
            :colorIndex="i"
            :displayNameTextColor="displayNameTextColor(i)"
            :hasUnderPriceText="hasUnderPriceText"
            :broadbandLabelPlacement="broadbandLabelPlacement"
            @packageSelected="packageSelected"
            @labelFooterClicked="toggleBroadbandLabel(i)"
            @thumbnailClicked="toggleBroadbandLabel(i)"
            :labelHtml="getLabelHtmlForPackageName(p.Name)"
          ></PackageNew>
          <BroadbandLabelBase
            :class="isFlipped[i] ? 'flipped-label' : ''"
            v-if="shouldShowLabel(i, p.Name)"
            :shopper="shopper"
            :packageData="p"
            :colorIndex="i"
            :selectButtonInLabel="true"
            :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
            :labelHtml="getLabelHtmlForPackageName(p.Name)"
            @labelClosed="toggleBroadbandLabel(i)"
            @packageSelected="packageSelected"
          ></BroadbandLabelBase>
        </div>
      </v-slide-item>
    </div>
  </v-slide-group>
  <div v-else :style="subgridContainerColumnStyles" class="subgrid-container">
    <template v-for="(p, i) in filteredPackages">
      <div :class="packageItemClass(i)">
        <PackageNew
          v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
          :class="isFlipped[i] ? 'flipped-package' : ''"
          :packageData="p"
          :colorIndex="i"
          :displayNameTextColor="displayNameTextColor(i)"
          :hasUnderPriceText="hasUnderPriceText"
          :broadbandLabelPlacement="broadbandLabelPlacement"
          @packageSelected="packageSelected"
          @labelFooterClicked="toggleBroadbandLabel(i)"
          @thumbnailClicked="toggleBroadbandLabel(i)"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
        ></PackageNew>
        <BroadbandLabelBase
          :class="isFlipped[i] ? 'flipped-label' : ''"
          v-if="shouldShowLabel(i, p.Name)"
          :shopper="shopper"
          :packageData="p"
          :colorIndex="i"
          :selectButtonInLabel="true"
          :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
          @labelClosed="toggleBroadbandLabel(i)"
          @packageSelected="packageSelected"
        ></BroadbandLabelBase>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import PackageNew from './PackageNew.vue'
import BroadbandLabelBase from './BroadbandLabelBase.vue'
import { ConfigKeys, Package } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import usePackage from './usePackage'
import { broadbandLabelParser } from './useBroadbandLabel'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { should } from 'chai'

export default defineComponent({
  name: 'PackageContainer',
  components: {
    PackageNew,
    BroadbandLabelBase
  },
  props: {
    filterPackages: {
      type: Array<Package>,
      required: true
    },
    packageSelected: Function
  },
  setup(props, { emit, root: { $vuetify } }) {
    const shopper = computed(() => $store.getters.getShopper)
    const filteredPackages = computed(() => props.filterPackages)
    const isExpanded = ref(new Array(filteredPackages.value.length).fill(false))
    const isFlipped = ref(new Array(filteredPackages.value.length).fill(false))
    const labelsGenerated = computed(() => $store.getters.getLabelsGenerated)
    const enableCarousel = computed(() => getConfigItem(ConfigKeys.enableCarousel) ?? false)
    const centeredItem = ref(0)

    const shouldUseCarousel = computed(() => {
      if (!enableCarousel.value) {
        return false
      }
      if ($vuetify.breakpoint.width > 1400) {
        return false
      }
      if ($vuetify.breakpoint.width < 600) {
        return true
      }
      if ($vuetify.breakpoint.width > 599 && $vuetify.breakpoint.width < 1024 && filteredPackages.value.length > 2) {
        return true
      }
      if ($vuetify.breakpoint.width > 1023 && $vuetify.breakpoint.width < 1401 && filteredPackages.value.length > 4) {
        return true
      }
      return false
    })

    const {
      selectPackage,
      displayNameTextColor,
      broadbandConfigString,
      broadbandLabelPlacement,
      approvedBroadbandLabelClasses,
      broadbandLabelToggleOption,
      packageMaxWidth
    } = usePackage($store, $vuetify, emit)

    //watch when props change, parse broadband label and add to labelsHtml
    //if broadband label is not already in labelsHtml (to prevent re-parsing, and dupliating DB records)
    watch(
      () => props.filterPackages,
      async (newVal) => {
        isFlipped.value = new Array(newVal.length).fill(false)
        isExpanded.value = new Array(newVal.length).fill(false)
        newVal?.forEach(async (p: Package) => {
          if (getLabelHtmlForPackageName(p.Name) || !p.BroadbandLabel?.[0]) {
            return
          }
          if (newVal.length >= 1 && shouldUseCarousel.value) {
            // even, divide by 2 (e.g: 4 / 2 = 2, which is the 3rd of 4)
            if (newVal.length % 2 === 0) {
              centeredItem.value = newVal.length / 2
            }
            // odd round down to nearest whole number e.g: floor(3 / 2) = 1, which is the 2nd of 3
            else {
              centeredItem.value = Math.floor(newVal.length / 2)
            }
          }
          const label = await broadbandLabelParser(
            shopper.value,
            p,
            broadbandConfigString.value,
            approvedBroadbandLabelClasses.value,
            broadbandLabelPlacement.value,
            p.BroadbandLabel?.[0]
          )
          filteredPackages.value.find((packageData: Package) => {
            if (packageData.Name === p.Name) {
              packageData.uniquePlanId = label.uniquePlanId
            }
          })
          // add generated label to store to avoid re-parsing on component remount from LOB changes
          $store.commit('addGeneratedLabel', { packageName: p.Name, html: label.html })
        })
      }
    )

    const carouselSubgridContainerStyles = computed(() => {
      return {
        gridTemplateColumns: `repeat(${filteredPackages.value.length}, minmax(225px, 340px))`
      }
    })

    const decrementCenteredItem = () => {
      centeredItem.value = centeredItem.value - 1
    }
    const incrementCenteredItem = () => {
      centeredItem.value = centeredItem.value + 1
    }

    const subgridContainerColumnStyles = computed(() => {
      // > 1023, max of 4 per row
      // If fewer than 4 packages, make as many columns as packages
      if ($vuetify.breakpoint.width > 1023) {
        return {
          gridTemplateColumns: `repeat(${
            filteredPackages.value.length >= 4 ? 4 : filteredPackages.value.length
          }, minmax(225px, 340px))`
        }
      }
      // > 599 < 1024, 2 per row
      if ($vuetify.breakpoint.width > 599 && $vuetify.breakpoint.width < 1024) {
        return {
          gridTemplateColumns: 'repeat(2, minmax(225px, 340px))'
        }
      }
      // Under 600, 1 per row
      return {
        gridTemplateColumns: 'repeat(1, minmax(225px, 340px))'
      }
    })

    const packageItemClass = (i: number) => {
      const numPackages = filteredPackages.value.length
      // For >1024, 4 cards fit in one row
      // Need to position the cards in grid based on the number of packages
      if ($vuetify.breakpoint.width > 1023) {
        return `item item-${i + 1}-of-${numPackages}`
      }
      // For >600, <1024 (2 per row), check if odd number of packges and if last item, give orphan-item class
      // since it will be on its own row and needs to be centered
      if ($vuetify.breakpoint.width > 599 && $vuetify.breakpoint.width < 1024) {
        if (filteredPackages.value.length % 2 === 1 && i === numPackages - 1) {
          return 'item orphan-item'
        }
      }
      // else just give normal item class
      return 'item'
    }

    const getLabelHtmlForPackageName = (name: string) => {
      return labelsGenerated.value.find((label) => label.packageName === name)?.html ?? undefined
    }

    const shouldShowLabel = (i: number, packageName: string) => {
      return (
        (isExpanded.value[i] ||
          isFlipped.value[i] ||
          broadbandLabelPlacement.value === 'below' ||
          broadbandLabelPlacement.value === 'standard') &&
        getLabelHtmlForPackageName(packageName)
      )
    }

    const toggleBroadbandLabel = (index: number) => {
      if (broadbandLabelToggleOption.value === 'expand') {
        isExpanded.value.splice(index, 1, !isExpanded.value[index])
      }
      if (broadbandLabelToggleOption.value === 'flip') {
        isFlipped.value.splice(index, 1, !isFlipped.value[index])
      }
    }

    const hasUnderPriceText = computed(() => {
      return filteredPackages.value?.some((filterPackage: any) => {
        return filterPackage['Under Price Text']
      })
    })

    const lobString = computed(() => {
      let filterPackage
      if (filteredPackages.value) {
        filterPackage = filteredPackages.value[0]
      }

      let lobString = ''

      const lobs = filterPackage?.lobs

      if (lobs?.includes('Internet')) {
        lobString += 'Internet'
      }

      if (lobs?.includes('TV')) {
        lobString += 'TV'
      }

      if (lobs?.includes('Latino TV')) {
        lobString += 'LatinoTV'
      }

      if (lobs?.includes('Phone')) {
        lobString += 'Phone'
      }

      return lobString.replaceAll(/,/g, '')
    })

    return {
      lobString,
      displayNameTextColor,
      hasUnderPriceText,
      filteredPackages,
      shopper,
      isExpanded,
      isFlipped,
      packageItemClass,
      broadbandLabelToggleOption,
      labelsGenerated,
      getLabelHtmlForPackageName,
      broadbandLabelPlacement,
      approvedBroadbandLabelClasses,
      broadbandConfigString,
      toggleBroadbandLabel,
      selectPackage,
      shouldShowLabel,
      subgridContainerColumnStyles,
      carouselSubgridContainerStyles,
      decrementCenteredItem,
      incrementCenteredItem,
      shouldUseCarousel,
      centeredItem,
      packageMaxWidth,
      enableCarousel
    }
  }
})
</script>

<style>
.v-slide-group .v-icon__svg {
  height: 36px;
  width: 36px;
}
.v-slide-group__prev,
.v-slide-group__next {
  align-self: flex-start;
  margin-top: 20vh;
  min-width: unset;
}
.subgrid-container {
  display: grid;
  justify-content: center;
  grid-row-gap: 12px;
  grid-column-gap: 24px;
}
.subgrid-container-carousel {
  display: grid;
  justify-content: center;
  grid-row-gap: 12px;
  grid-column-gap: 24px;
}
.v-item-group svg {
  fill: var(--v-primary-base);
}
.subgrid-container .item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: auto / span 2;
  justify-content: center;
}
.subgrid-container-carousel .item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: auto / span 2;
  justify-content: center;
  max-width: 60vw;
}
.subgrid-container-carousel .broadband-card {
  margin-bottom: 12px;
}
.package {
  min-width: 225px;
  max-width: 340px;
  grid-row: 1 / 2;
}
.broadband-card {
  min-width: 225px;
  max-width: 340px;
  justify-self: center;
  margin-top: 12px;
  grid-row: 2 / 3;
  display: grid;
  padding: 12px;
  grid-template-rows: min-content auto min-content;
  word-break: break-word;
}
.flipped-package {
  display: none;
}
.flipped-label {
  margin-top: 0px;
  grid-row: auto / span 2;
}
.subgrid-container .item-5-of-6 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.subgrid-container .item-6-of-6 {
  grid-column-start: 3;
  grid-column-end: 4;
}
.subgrid-container .item-5-of-5 {
  grid-column: 1 / -1;
}
.subgrid-container .orphan-item {
  grid-column: 1 / -1;
}
.v-item-group {
  margin-right: 12px;
}
</style>
