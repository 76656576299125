
<v-slide-group
  v-if="shouldUseCarousel"
  @click:next="incrementCenteredItem"
  @click:prev="decrementCenteredItem"
  center-active
  v-model="centeredItem"
  show-arrows="always"
>
  <div :style="carouselSubgridContainerStyles" class="subgrid-container-carousel">
    <v-slide-item v-for="(p, i) in filteredPackages">
      <div :class="packageItemClass(i)">
        <PackageNew
          v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
          :class="isFlipped[i] ? 'flipped-package' : ''"
          :packageData="p"
          :colorIndex="i"
          :displayNameTextColor="displayNameTextColor(i)"
          :hasUnderPriceText="hasUnderPriceText"
          :broadbandLabelPlacement="broadbandLabelPlacement"
          @packageSelected="packageSelected"
          @labelFooterClicked="toggleBroadbandLabel(i)"
          @thumbnailClicked="toggleBroadbandLabel(i)"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
        ></PackageNew>
        <BroadbandLabelBase
          :class="isFlipped[i] ? 'flipped-label' : ''"
          v-if="shouldShowLabel(i, p.Name)"
          :shopper="shopper"
          :packageData="p"
          :colorIndex="i"
          :selectButtonInLabel="true"
          :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
          @labelClosed="toggleBroadbandLabel(i)"
          @packageSelected="packageSelected"
        ></BroadbandLabelBase>
      </div>
    </v-slide-item>
  </div>
</v-slide-group>
<div v-else :style="subgridContainerColumnStyles" class="subgrid-container">
  <template v-for="(p, i) in filteredPackages">
    <div :class="packageItemClass(i)">
      <PackageNew
        v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
        :class="isFlipped[i] ? 'flipped-package' : ''"
        :packageData="p"
        :colorIndex="i"
        :displayNameTextColor="displayNameTextColor(i)"
        :hasUnderPriceText="hasUnderPriceText"
        :broadbandLabelPlacement="broadbandLabelPlacement"
        @packageSelected="packageSelected"
        @labelFooterClicked="toggleBroadbandLabel(i)"
        @thumbnailClicked="toggleBroadbandLabel(i)"
        :labelHtml="getLabelHtmlForPackageName(p.Name)"
      ></PackageNew>
      <BroadbandLabelBase
        :class="isFlipped[i] ? 'flipped-label' : ''"
        v-if="shouldShowLabel(i, p.Name)"
        :shopper="shopper"
        :packageData="p"
        :colorIndex="i"
        :selectButtonInLabel="true"
        :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
        :labelHtml="getLabelHtmlForPackageName(p.Name)"
        @labelClosed="toggleBroadbandLabel(i)"
        @packageSelected="packageSelected"
      ></BroadbandLabelBase>
    </div>
  </template>
</div>
